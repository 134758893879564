@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

.fade-in {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.5s, transform 0.5s;
  }
  
  .fade-in.is-visible {
    opacity: 1;
    transform: translateY(0);
  }
  
  body {
    font-family: "Lato", sans-serif;
    background-color: #FAF8F5;
  }